import { TranslationKey } from '~/lib/data-contract';

type ValidateFiles = {
    files: FileList | null;
    fileSizeLimits: Record<string, number>;
    maxFiles?: number;
    translate: (key: TranslationKey) => string;
};

export const acceptedFiles =
    '.pdf, .jpeg, .png, .docx, .doc, .xls, .xlsx, .odt, .ods, .odp, .txt, .gif, .bmp';

export const defaultFileSizeLimits: Record<string, number> = {
    'application/pdf': 20 * 1024 * 1024, // 20 MB
    'image/jpeg': 10 * 1024 * 1024, // 10 MB
    'image/png': 10 * 1024 * 1024, // 10 MB
    'image/gif': 10 * 1024 * 1024, // 10 MB
    'image/bmp': 10 * 1024 * 1024, // 10 MB
    'application/msword': 20 * 1024 * 1024, // Word - 20 MB
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 20 * 1024 * 1024, // Word - 20 MB
    'application/vnd.oasis.opendocument.text': 20 * 1024 * 1024, // Open Office - 20 MB
    'application/vnd.oasis.opendocument.presentation': 20 * 1024 * 1024, // Open Office PP - 20 MB
    'text/plain': 5 * 1024 * 1024, // Note - 5 MB
    'application/vnd.ms-excel': 20 * 1024 * 1024, // Excel - 20 MB
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 20 * 1024 * 1024, // Excel - 20 MB
    'application/vnd.oasis.opendocument.spreadsheet': 20 * 1024 * 1024, // Excel - 20 MB
};

export const validateFiles = ({
    maxFiles = 10,
    files,
    fileSizeLimits,
    translate,
}: ValidateFiles) => {
    if (!files || files.length === 0) {
        return true;
    }

    if (files.length > maxFiles) {
        return translate('Kompan.ContactForm.FileInputMaxFiles')?.replace(
            '[x]',
            maxFiles.toString(),
        );
    }

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const maxSize = fileSizeLimits[file.type];

        if (!maxSize) {
            return translate('Kompan.ContactForm.FileInputFileTypeFormateError')?.replace(
                '[x]',
                file.name.toString(),
            );
        }

        if (file.size > maxSize) {
            return translate('Kompan.ContactForm.FileInputFileSizeError')
                ?.replace('[x]', file.name.toString())
                ?.replace('[y]', (maxSize / (1024 * 1024)).toString());
        }
    }

    return true;
};
